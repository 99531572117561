// import * as React from "react";
import React, { useEffect } from 'react';
// import { graphql, Link } from "gatsby"
// import "../../css/talent.css";
import Body from "../../images/hinata/Hinata_model.png";
import Key from "../../images/hinata/Hinata_key.png";
import number from "../../images/hinata/04.png";
import logo from "../../images/hinata/Hinata_tag.png";
import TalentCore from '../../component/talentCore';

const hinata_details = {
   name: `hinata`,
   description: `ある日オーディション会場に迷いこみ、
   突如アイドルをやることになった。おっとりしているがおっちょこちょい。
   好きなことに夢中になると周りが見えなくなるところがある。`,
   slogan_top: `「好きなことと出来ることって違うんだ」`,
   slogan_bot: `「ひなの世界へ連れてっちゃるけん！」`,
   twitter: "https://x.com/natori_hinata",
   youtube: "https://www.youtube.com/@natori_hinata",
   hashtag: "#菜鳥ひなた",
   illustrator: "塩かずのこ",
   illustrator_link: "https://x.com/Siokazunoko",
   modeler: "こん太",
   modeler_link: "https://x.com/Kontalo_ch",
   number: number,
   logo: logo,
   keyIllust: Key,
   baseIllust: Body
}

const Natori_Hinata = () => {
   return (
      <TalentCore details={hinata_details}/>
   )
}

export default Natori_Hinata